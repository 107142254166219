import React from 'react'
import MUiTypography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const variantMapping = {
  h4: 'h3', h5: 'h3', h6: 'h3'
}
const useStyles = makeStyles({
  h1: {
    fontSize: '2.5rem',
  }, h2: {
    fontSize: '2rem',
    fontWeight: '500',
    lineHeight: '2'
  }, h3: {
    fontSize: '1.2rem',
    lineHeight: '1.5'
  }, body1: {
    fontSize: '1rem',
    lineHeight: '2'
  }, body2: {
    fontSize: '0.9rem',
    lineHeight: '1.5'
  }, subtitle1: {
    fontSize: '0.8rem',
    lineHeight: '1.5',
    color: '#aaa'
  }, subtitle2: {
    fontSize: '0.8rem',
    lineHeight: '1.5'
  }
});

const Typography = (props) => {
  const classes = useStyles()

  return (
    <MUiTypography classes={classes} variantMapping={variantMapping} {...props}></MUiTypography>
  )
}

export default Typography