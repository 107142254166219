const colors = {
  primary     : '#611a87',
  secondary   : '#27eed7',
  background  : {
    primary   : '#fff',
  },
  font        : {
    primary   : '#000',
    secondary : '#C0FDFB',
  },
  header      : {
    bg        : '#611a87'
  },
  footer      : {
    bg        : '#27eed7',
    bgLight   : '#1ea0c0'
  },
  appBarMenu  : {
    bg        : '#611a87',
    selectedBg: '#0a424f44'
  },
  button      : {
    bg        : '#27eed7',
    color     : '#000'
  },
  textfield   : {
    label     : '#fff'
  }
}

export default colors