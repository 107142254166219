import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'

import AppBar from './Appbar';

// const useStyles = makeStyles({
//   background1: {
//     height: '80px',
//     backgroundRepeat: 'repeat',
//     backgroundSize: 'auto',
//   },background2: {
//     height: '50px',
//     backgroundRepeat: 'repeat',
//     backgroundSize: '50px 50px',
//   }
// })

const Header = ({ siteMetaData }) => {

  return(
    <>
      <AppBar siteMetaData={siteMetaData}></AppBar>
    </>
  )

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}


export default Header