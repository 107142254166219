import { createMuiTheme } from '@material-ui/core/styles'
import colors from '../variables/colors'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Roboto',
      'Neuton',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ],
    h1: {
      color: colors.font.primary
    },
    h2: {
      color: colors.font.primary
    },
    fontSize: 12,
  },
  spacing: 4,
})


export default theme
