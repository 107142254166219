/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'

import Header from "../Header"
import Footer from '../Footer'
import theme from '../../utils/theme'
import colors from '../../variables/colors'
import './Layout.css'

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.background.primary
  },
  header: {
    width: '100%',
    height: '80px',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2
  },
  container: {
    padding: 0,
    marginTop: '70px'
  },
  background1: {
    height: '80px',
    backgroundRepeat: 'repeat',
    backgroundSize: 'auto',
    marginTop: '65px !important'
  },
  button: {
    position: 'fixed',
    bottom: '20px',
    right: '20px'
  },
  footer: {
  },
  '@media screen and (min-width: 599px)': {
    header: {
      height: '160px'
    },
    container: {
      marginTop: '160px'
    }
  }
})

// When the user scrolls down 50px from the top of the document, resize the header's font size

function scrollFunction() {
  if (typeof window !== 'undefined') {
    const document = window.document
    if (window.innerWidth > 599) {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        document.getElementById("header").style.height = "100px";
        if (document.getElementById("appbarLogo")) {
          document.getElementById("appbarLogo").style.width = "170px";
          document.getElementById("appbarLogo").style.height = "80px";
        }
      } else {
        document.getElementById("header").style.height = "160px";
        if (document.getElementById("appbarLogo")) {
          document.getElementById("appbarLogo").style.width = "245px";
          document.getElementById("appbarLogo").style.height = "115px";
        }
      }
    }
  }
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          location
          telephone
        }
      }
    }
  `)
  const classes = useStyles();
  if (typeof window !== 'undefined') {
    window.onscroll = function() {scrollFunction()};
  }

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.root}>
        <header id="header" className={classes.header}>
          <Header siteMetaData={data.site.siteMetadata} />
        </header>
        <Container className={classes.container} maxWidth="xl">
          <main>{children || ''}</main>
        </Container>
        <footer className={classes.footer}>
          <Footer />
        </footer>
      </Box>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
