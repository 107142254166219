import React from 'react'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

import { Grid } from "@material-ui/core"
import Typography from '../Typography'
import { makeStyles } from '@material-ui/core/styles'
import colors from '../../variables/colors'
import compStyles from './Footer.module.scss'

const useStyles = makeStyles({
  section: {
    backgroundColor: colors.footer.bg,
    borderTopLeftRadius: '25%',
    borderTopRightRadius: '25%',
    marginTop: '40px',
    
    '@media screen and (min-width: 599px)': {
      borderTopLeftRadius: '50%',
      borderTopRightRadius: '50%'
    }
  },
  logoContainer: {
    padding: '40px 0'
  },
  logo: {
    width: '120px',
    display: 'block !important',
    margin: '0 auto',

    '@media screen and (min-width: 599px)': {
      width: '240px'
    }
  },
  main: {
    padding: '12px',
  },
  mainText: {
  },
  siteLink: {
    lineHeight: 2
  },
  link : {
    textDecoration: 'none',
    color: 'inherit'
  },
  contactInfo: {
    minWidth: '320px',
    textAlign: 'center',
    
    '@media screen and (min-width: 599px)': {
      width: '360px',
      textAlign: 'left',
      paddingLeft: '100px'
    }
  },
  address: {
    lineHeight: 1
  },
  phone: {
    marginTop: '10px'
  },
  email: {
    marginTop: '5px'
  },
  socialContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0',
    '@media screen and (min-width: 599px)': {
      justifyContent: 'flex-start'
    }
  },
  social: {
    marginRight: '15px'
  },
  designedby: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '@media screen and (min-width: 599px)': {
      justifyContent: 'space-between',
      padding: '0  30px 0 80px'
    }
  },
  textReserved: {
    fontSize: '0.8em',
    color: '#000',
    padding: '20px',
    display: 'flex',
    textDecoration: 'none'
  }
});

const Footer = (props) => {
  const classes = useStyles()
  const footer = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo_black.png" }) {
        childImageSharp {
          fluid(maxWidth: 240 ) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      facebook: file(relativePath: { eq: "social/facebook.png" }) {
        childImageSharp {
          fixed(width: 32 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      instagram: file(relativePath: { eq: "social/instagram.png" }) {
        childImageSharp {
          fixed(width: 32 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      youtube: file(relativePath: { eq: "social/youtube.png" }) {
        childImageSharp {
          fixed(width: 40 ) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div className={compStyles.footer}>
      <div className={`${compStyles.section}`}>
      <section  className={`${compStyles.section2}`}>

        <div className={classes.logoContainer}>
          <Img fluid={footer.logo.childImageSharp.fluid} className={classes.logo} />
        </div>
        <Grid container className={compStyles.main2} justify="space-evenly">
          <Grid item md={4} className={classes.contactInfo}>
            <Typography variant="h3" className={classes.mainText}>MENU</Typography>
            <Typography variant="body1" className={classes.siteLink}><Link to="/home"  className={classes.link}>Home</Link></Typography>
            <Typography variant="body1" className={classes.siteLink}><Link to="/exploreCourses"  className={classes.link}>Explore Courses</Link></Typography>
            <Typography variant="body1" className={classes.siteLink}><Link to="/contactUs"  className={classes.link}>Contact Us</Link></Typography>
            <Typography variant="body1" className={classes.siteLink}><Link to="/enquiryForm"  className={classes.link}>Enquiry Form</Link></Typography>
          </Grid>
          <Grid item md={4} className={classes.contactInfo}>
            <Typography variant="h3" className={classes.mainText}>CONTACT US</Typography>
            <Typography variant="body1" className={classes.phone}><a href="tel:+919779660098" className={classes.link}>Phone: +91 97796 60098</a></Typography>
            <Typography variant="body1" className={classes.email}><a href="tel:+919779660098" className={classes.link}>email id: info@soundgarage.in</a></Typography>
          </Grid>
          <Grid item md={4} className={classes.contactInfo}>
            <Typography variant="body1">Follow us on</Typography>
            <Typography variant="h3" className={classes.mainText}>SOCIAL MEDIA</Typography>
            <div className={classes.socialContainer}>
              <Link to="https://www.facebook.com/SoundGarageChennai/" className={classes.social}><Img fixed={footer.facebook.childImageSharp.fixed} /></Link>
              <Link to="https://www.instagram.com/soundgarage.in/" className={classes.social}><Img fixed={footer.instagram.childImageSharp.fixed} /></Link>
            </div>
          </Grid>
          <Grid item md={12} className={classes.designedby}>
            <Typography variant="body2" className={classes.textReserved}>ALL RIGHTS RESERVED</Typography>
            <Link to="https://advaithdesignstudio.wordpress.com/" className={classes.textReserved}><Typography variant="body2">Website Designed & Developed by Advaith Design Studio</Typography></Link>
          </Grid>
        </Grid>
      </section>
      </div>
    </div>
  )
}
export default Footer