/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import Helmet from "react-helmet"
 import { useStaticQuery, graphql } from "gatsby"
 
 function SEO({ title, description, keywords, lang, robots, children }) {
   const { site } = useStaticQuery(
     graphql`
       query {
         site {
           siteMetadata {
             title
             description
             author
           }
         }
       }
     `
   )
 
   const metaDescription = description || site.siteMetadata.description
   
   const metaInfoList = [{
     name: `description`,
     content: metaDescription,
   }, {
     name: `author`,
     content: site.siteMetadata.author,
   }, {
     property: `og:title`,
     content: title,
   }, {
     property: `og:description`,
     content: metaDescription,
   }, {
     property: `og:type`,
     content: `website`,
   }, {
     name: `twitter:card`,
     content: `summary`,
   }, {
     name: `twitter:creator`,
     content: site.siteMetadata.author,
   }, {
     name: `twitter:title`,
     content: title,
   }, {
     name: `twitter:description`,
     content: metaDescription,
   }, {
     name: `keywords`,
     content: keywords || `Social work, NGO`
   }, {
     name: `robots`,
     content: robots || `index, follow`
   }]
 
   return (<div>
     {/* <div>hello</div> */}
     <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}>
      <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
      {
      metaInfoList.map(({name, content}, index) => (<meta key={index} name={name} content={content} />))
      }

      <script src="https://www.googletagmanager.com/gtag/js?id=G-M1R3B05EWZ"></script>
      <script>
      {` window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-M1R3B05EWZ');`}
      </script>

      <script>
      {`!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '377739981575529');
      fbq('track', 'PageView');
      `}
      </script>
      {children}
     </Helmet>
   </div>)
 }
 
 SEO.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
 }
 
 SEO.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
 }
 
 export default SEO
 