import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import { window } from 'browser-monads'
import Img from "gatsby-image"
import { Link } from 'gatsby'
import colors from '../../variables/colors'

import { makeStyles } from '@material-ui/core/styles';
import MobileAppbar from './MobileAppbar';

const useStyles = makeStyles( theme => ({
  menuBar: {
    height: 'inherit',
    backgroundColor: colors.header.bg,
    fontFamily: 'roboto',
    boxShadow: '0px 2px 15px #000',
    marginBottom: '5px'
  },
  menuContainer: {
    height: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 20px',
    fontFamily: 'montserrat',
    '@media screen and (min-width: 599px)': {
      padding: '0 40px 0 60px',
    }
  },
  menuList: {
    display: 'none',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    listStyle: 'none',
    margin: '0',

    '@media screen and (min-width: 599px)': {
      display: 'flex'
    }
  },
  menuListMobile: {
    listStyle: 'none',
    fontFamily: 'roboto'
  },
  mobileMenuList: {
    display: 'block',
    position: 'absolute',
    top: '10px',
    right: '10px',
    '@media screen and (min-width: 599px)': {
      display: 'none'
    }
  },
  menuItem: {
    color: 'white',
    padding: '5px 10px',
    margin: '0 5px',
    cursor: 'pointer',

    '& a': {
      color: 'white',
      textDecoration: 'none'
    },
    
    "& a:hover": {
      color: '#27eed7'
    }
  },
  active: {
    color: '#27eed7 !important',
    '& a': {
      color: 'inherit !important',
    },

    '@media screen and (min-width: 599px)': {
      color: '#27eed7 !important',
      borderBottom: '2px solid #27eed7'
    }
  },
  logo: {
    width: '160px',
    height: '70px',

    '@media screen and (min-width: 599px)': {
      width: '245px',
      height: '115px' 
    }
  },
  mobileLogo: {
    margin: '20px 30px'
  }
}));

const pageReload = () => {
  if (typeof window !== 'undefined') {
    window.location.href = '/'
  }
}
const AppBar = ({siteMetaData}) => {
  const classes = useStyles();

  const appbar = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo_white.png" }) {
        childImageSharp {
          fluid(maxWidth: 280 ) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      mobileLogo: file(relativePath: { eq: "logo_white.png" }) {
        childImageSharp {
          fixed(width: 260 ) {
            ...GatsbyImageSharpFixed
          }
        }
      },
    }
  `)
  const isBrowser = () => typeof window !== "undefined"
  const activePath = (isBrowser() && window.location.pathname) || '/'

  return (
    <div className={classes.menuBar} height="100%">
      <nav className={classes.menuContainer}>
        <Link to="/home" onClick={pageReload}><img id="appbarLogo" src="/logo/logo-white.png" title="Home" alt="sound garage" className={classes.logo}/></Link>
        <div>
          <ul className={classes.menuList}>
            <li className={`${classes.menuItem} ${(activePath === '/' || activePath === '/home' || activePath === '/home/') ? classes.active: 'hover-underline-animation'}`}><Link to="/home" onClick={pageReload}>HOME</Link></li>
            <li className={`${classes.menuItem} ${activePath === '/exploreCourses' ? classes.active: 'hover-underline-animation' }`}><Link to="/exploreCourses">EXPLORE COURSES</Link></li>
            <li className={`${classes.menuItem} ${activePath === '/contactUs' ? classes.active: 'hover-underline-animation'}`}><Link to='/contactUs'>CONTACT US</Link></li>
            <li className={`${classes.menuItem} ${activePath === '/enquiryForm' ? classes.active: 'hover-underline-animation'}`}><Link to='/enquiryForm'>ENQUIRY FORM</Link></li>
          </ul>
        </div>
      </nav>
      <div className={classes.mobileMenuList}>
        <MobileAppbar>
          <a href="/" className={classes.mobileLogo} onClick={pageReload}><Img fixed={appbar.mobileLogo.childImageSharp.fixed} title="Home" alt="sound-garage" /></a>
          <ul className={classes.menuListMobile}>
            <li className={`${classes.menuItem} ${(activePath === '/' || activePath === '/home' || activePath === '/home/') && classes.active}`}><a href="/home" onClick={pageReload}>HOME</a></li>
            <li className={`${classes.menuItem} ${activePath.includes('exploreCourses') && classes.active}`}><a href='/exploreCourses'>EXPLORE COURSES</a></li>
            <li className={`${classes.menuItem} ${activePath.includes('contactUs') && classes.active}`}><a href='/contactUs'>CONTACT US</a></li>
            <li className={`${classes.menuItem} ${activePath.includes('enquiryForm') && classes.active}`}><a href='/enquiryForm'>ENQUIRY FORM</a></li>
          </ul>
        </MobileAppbar>
      </div>
    </div>
  )
}

export default AppBar
